import React, { useState, useEffect } from "react";
import axios from "axios";
import { Button } from "./ui/button"; // Importing named Button

const TipCalculator = () => {
  const [bill, setBill] = useState(0);
  const [tipPercentage, setTipPercentage] = useState(15);
  const [isSplitting, setIsSplitting] = useState(false);
  const [splitCount, setSplitCount] = useState(2);
  const [customTipPercentage, setCustomTipPercentage] = useState("");
  const [currencySymbol, setCurrencySymbol] = useState("₹"); // Default to Indian Rupee
  const [currencyCode, setCurrencyCode] = useState("INR"); // Default to INR

  // Fetch currency symbol and code based on IP address
  const fetchCurrencyInfo = async () => {
    try {
      // Fetch user's IP information
      const ipResponse = await axios.get("https://ipinfo.io/json");
      const countryCode = ipResponse.data.country;

      // Fetch currency info based on country code
      const currencyResponse = await axios.get(
        `https://restcountries.com/v3.1/alpha/${countryCode}`
      );
      const currency =
        currencyResponse.data.currencies[
          Object.keys(currencyResponse.data.currencies)[0]
        ];

      setCurrencySymbol(currency.symbol || "₹"); // Default to ₹ if symbol not found
      setCurrencyCode(currency.code || "INR"); // Default to INR if code not found
    } catch (error) {
      console.error("Error fetching currency:", error);
      setCurrencySymbol("₹"); // Default to ₹ on error
      setCurrencyCode("INR"); // Default to INR on error
    }
  };

  useEffect(() => {
    fetchCurrencyInfo(); // Fetch currency info on component mount
  }, []);

  // Calculate tip amount based on custom percentage or predefined percentage
  const billAmount = Number(bill) || 0;
  const effectiveTipPercentage = customTipPercentage
    ? Number(customTipPercentage)
    : tipPercentage;
  const tipAmount = billAmount * (effectiveTipPercentage / 100);
  const total = billAmount + tipAmount;
  const splitAmount = isSplitting ? total / splitCount : total;

  const quickTipButtons = [5, 10, 15, 20];

  const handleCustomTipChange = (e) => {
    const value = e.target.value;
    setCustomTipPercentage(value);
    if (value > 0) {
      setTipPercentage(0); // Reset to custom tip mode
    }
  };

  const handleBillChange = (e) => {
    // Remove leading zeros
    const value = e.target.value.replace(/^0+(?!\.|$)/, "");
    setBill(value ? Number(value) : "");
  };

  const handleReset = () => {
    setBill(0);
    setTipPercentage(15); // Set to default or custom tip percentage
    setIsSplitting(false);
    setSplitCount(2);
    setCustomTipPercentage("");
    fetchCurrencyInfo(); // Re-fetch currency info on reset
  };

  return (
    <div className="w-full max-w-md mx-auto bg-white shadow-lg rounded-3xl p-6 sm:p-8 lg:p-12 border border-gray-300">
      <h2 className="text-2xl sm:text-3xl font-bold mb-6 text-blue-600 text-center">
        Tip Calculator
      </h2>
      <div className="space-y-6">
        {/* Bill input */}
        <div className="relative">
          <label
            htmlFor="bill"
            className="block text-sm font-medium text-gray-700"
          >
            Bill
          </label>
          <input
            id="bill"
            type="number"
            value={bill || ""}
            onChange={handleBillChange}
            className="mt-1 block w-full rounded-lg border border-gray-300 shadow-sm pr-12 py-3 text-right focus:border-blue-500 focus:ring focus:ring-blue-200 focus:ring-opacity-50 transition duration-300 ease-in-out hover:border-blue-400 hover:bg-blue-50"
          />
          <span
            className={`absolute right-4 top-2/3 transform -translate-y-1/2 text-gray-500 transition duration-300 ease-in-out ${
              bill ? "opacity-0" : "opacity-100"
            }`}
          >
            {currencyCode}
          </span>
        </div>

        {/* Quick Tip buttons */}
        <div>
          <label className="block text-sm font-medium text-gray-700">
            Quick Tip
          </label>
          <div className="flex flex-wrap gap-3 mt-3">
            {quickTipButtons.map((percentage) => (
              <Button
                key={percentage}
                variant={tipPercentage === percentage ? "default" : "outline"}
                size="default"
                onClick={() => {
                  setCustomTipPercentage(""); // Reset custom tip percentage when a quick tip is selected
                  setTipPercentage(percentage);
                }}
                className={`transition duration-300 ease-in-out transform hover:scale-105 ${
                  tipPercentage === percentage
                    ? "bg-blue-500 text-white"
                    : "bg-blue-200 text-blue-600 hover:bg-blue-300"
                } rounded-full px-6 py-3`}
              >
                {percentage}%
              </Button>
            ))}
            <Button
              variant={tipPercentage === 0 ? "default" : "outline"}
              size="default"
              onClick={() => setTipPercentage(0)}
              className={`transition duration-300 ease-in-out transform hover:scale-105 ${
                tipPercentage === 0
                  ? "bg-blue-500 text-white"
                  : "bg-blue-200 text-blue-600 hover:bg-blue-300"
              } rounded-full px-6 py-3`}
            >
              Custom
            </Button>
          </div>
          {tipPercentage === 0 && (
            <div className="mt-3">
              <label
                htmlFor="customTip"
                className="block text-sm font-medium text-gray-700"
              >
                Custom Tip Percentage
              </label>
              <input
                id="customTip"
                type="number"
                value={customTipPercentage}
                onChange={handleCustomTipChange}
                className="mt-1 block w-full py-3 rounded-lg border border-gray-300 shadow-sm focus:border-blue-500 focus:ring focus:ring-blue-200 focus:ring-opacity-50 transition duration-300 ease-in-out hover:border-blue-400 hover:bg-blue-50"
              />
            </div>
          )}
        </div>

        {/* Tip Percentage Slider */}
        <div>
          <label
            htmlFor="tip"
            className=" block text-sm font-medium text-gray-700"
          >
            Tip:{" "}
            {tipPercentage !== 0 ? `${tipPercentage}%` : "Custom Percentage"}
          </label>
          <input
            id="tip"
            type="range"
            min="0"
            max="30"
            value={tipPercentage}
            onChange={(e) => setTipPercentage(Number(e.target.value))}
            className={`mt-1 block w-full border border-gray-300 rounded-lg transition duration-300 ease-in-out ${
              tipPercentage === 0 ? "cursor-not-allowed" : "hover:bg-blue-50"
            }`}
            disabled={tipPercentage === 0}
          />
        </div>

        {/* Tip Amount */}
        <div>
          <label className="block text-sm font-medium text-gray-700">
            Tip amount
          </label>
          <input
            value={`${currencySymbol} ${tipAmount.toFixed(2)}`}
            readOnly
            className="mt-1 block w-full py-3 rounded-lg border border-gray-300 shadow-sm bg-gray-50 transition duration-300 ease-in-out hover:bg-blue-50 text-right"
          />
        </div>

        {/* Total */}
        <div>
          <label className="block text-sm font-medium text-gray-700">
            Total
          </label>
          <input
            value={`${currencySymbol} ${total.toFixed(2)}`}
            readOnly
            className="mt-1 py-3 block w-full rounded-lg border border-gray-300 shadow-sm bg-gray-50 transition duration-300 ease-in-out hover:bg-blue-50 text-right"
          />
        </div>

        {/* Splitting Option */}
        <div className="flex items-center">
          <input
            id="splitting"
            type="checkbox"
            checked={isSplitting}
            onChange={(e) => setIsSplitting(e.target.checked)}
            className="rounded border-gray-300 text-blue-600 shadow-sm focus:border-blue-300 focus:ring focus:ring-offset-0 focus:ring-blue-200 focus:ring-opacity-50"
          />
          <label
            htmlFor="splitting"
            className="ml-2 block text-sm text-gray-900"
          >
            Are you splitting the bill?
          </label>
        </div>

        {/* Split Count */}
        {isSplitting && (
          <div>
            <label
              htmlFor="splitCount"
              className="block text-sm font-medium text-gray-700"
            >
              Number of people
            </label>
            <input
              id="splitCount"
              type="number"
              value={splitCount}
              onChange={(e) => setSplitCount(Number(e.target.value))}
              className="mt-1 block w-full py-3 rounded-lg border border-gray-300 shadow-sm focus:border-blue-500 focus:ring focus:ring-blue-200 focus:ring-opacity-50 transition duration-300 ease-in-out hover:border-blue-400 hover:bg-blue-50 text-center"
            />
          </div>
        )}
      </div>
      {/* Per Person */}
      {isSplitting && (
        <div>
          <label className="block text-sm font-medium text-gray-700">
            Per Person
          </label>
          <input
            value={`${currencySymbol} ${splitAmount.toFixed(2)}`}
            readOnly
            className="mt-1 py-3 block w-full rounded-md border border-gray-300 shadow-sm bg-gray-50 transition duration-300 ease-in-out hover:bg-blue-50 text-right"
          />
        </div>
      )}

      {/* Reset Button */}
      <div className="mt-6 text-center">
        <Button
          variant="default"
          size="default"
          onClick={handleReset}
          className="rounded-full px-6 py-3 bg-red-500 text-white hover:bg-red-600 transition duration-300 ease-in-out transform hover:scale-105"
        >
          Reset
        </Button>
      </div>
    </div>
  );
};

export default TipCalculator;
